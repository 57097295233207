import React from "react";
import {
  Cancel,
  CheckCircle,
  CloudUpload,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import * as z from "zod";
import { getAppConfig } from "~/apps/studio/config";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import type { Option } from "~/components/Form";
import {
  ComboBox,
  FileField,
  requiredFile,
  TextField,
  useField,
  useStudioForm,
} from "~/components/Form";
import { requiredText, requiredUuid } from "~/domain/common";
import { useAllGroups } from "~/domain/logs";
import { MultipartUploader } from "~/domain/network";
import { formatBytes, formatPercent } from "~/format";
import { Page } from "~/layout";
import { isEmpty } from "~/lib/std";
import { NOTEBOOK_HELP_LINK } from "~/links";
import type { GroupListResponse, Log } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { assertNever } from "~/utils/assertNever";
import { buildLqsRestApiDocsEndpoint } from "~/utils/build-api-endpoints";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import { UploadResponseError, useUploadLog } from "./queries";

const schema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  file: requiredFile,
});

export function UploadPage() {
  const uploadLog = useUploadLog();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useStudioForm({
    schema,
    defaultValues: {
      name: null,
      groupId: null,
      file: null,
    },
    onSubmit(values) {
      uploadLog.mutate(values, {
        onError(error) {
          if (error instanceof UploadResponseError) {
            if (error.type === "name:duplicate") {
              setError("name", {
                message: "A log with that name already exists",
              });
            }
          }
        },
      });
    },
  });

  const { name, file } = watch();

  const groupOptionsQuery = useAllGroups({ select: selectGroupOptions });
  const groupField = useField({ control, name: "groupId" });

  let nameCompletionStatus: SectionCompletionStatus = "incomplete";
  if (errors.name != null) {
    nameCompletionStatus = "error";
  } else if (name != null) {
    nameCompletionStatus = "complete";
  }

  let groupCompletionStatus: SectionCompletionStatus = "incomplete";
  if (errors.groupId != null) {
    groupCompletionStatus = "error";
  } else if (groupField.value != null) {
    groupCompletionStatus = "complete";
  }

  let fileCompletionStatus: SectionCompletionStatus = "incomplete";
  if (errors.file != null) {
    fileCompletionStatus = "error";
  } else if (file != null) {
    fileCompletionStatus = "complete";
  }

  const completionStatuses: Record<
    "name" | "group" | "file",
    SectionCompletionStatus
  > = {
    name: nameCompletionStatus,
    group: groupCompletionStatus,
    file: fileCompletionStatus,
  };

  const beginUploadHandleProps = getEventHandlerProps(
    "onClick",
    completionStatuses.name === "complete" &&
      completionStatuses.group === "complete" &&
      completionStatuses.file === "complete" &&
      !uploadLog.isSuccess &&
      function handleBeginUpload(): void {
        handleSubmit();
      },
  );

  return (
    <Page title="Upload">
      <Container fixed>
        <Stack spacing={4}>
          <Card
            title={renderSectionHeader(completionStatuses.name, "Log Name")}
          >
            <Box sx={{ width: "40ch" }}>
              <TextField control={control} name="name" required />
            </Box>
          </Card>
          <Card title={renderSectionHeader(completionStatuses.group, "Group")}>
            <Box sx={{ width: "30ch" }}>
              <ComboBox
                name="groupId"
                value={groupField.value}
                onChange={groupField.onChange}
                optionsQuery={groupOptionsQuery}
                errorMessage={groupField.errorMessage}
                required
              />
            </Box>
          </Card>
          <Card title={renderSectionHeader(completionStatuses.file, "File")}>
            <FileField
              control={control}
              name="file"
              setError={setError}
              clearErrors={clearErrors}
              accept={{
                "application/octet-stream": [".bag", ".mcap"],
              }}
              acceptedFormats={[".bag", ".mcap"]}
            />
            <Details>
              <Details.Summary>
                How can I upload logs larger than{" "}
                {formatBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)}?
              </Details.Summary>
              <Details.Content paragraph>
                To upload a log larger than{" "}
                {formatBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)} you'll need
                to use the{" "}
                <Link color="inherit" href={buildDocsEndpoint()}>
                  DataStore REST API
                </Link>{" "}
                directly. Using the API, you can upload logs up to 5 TiB.
              </Details.Content>
              <Details.Content>
                Follow this detailed guide on{" "}
                <Link color="inherit" href={NOTEBOOK_HELP_LINK}>
                  using the API to upload large files
                </Link>
                .
              </Details.Content>
            </Details>
          </Card>
          <Card title="Upload">
            <Stack spacing={2} sx={{ alignItems: "baseline" }}>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.name)}
                  </ListItemIcon>
                  <ListItemText>Log Name</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.group)}
                  </ListItemIcon>
                  <ListItemText>Group</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.file)}
                  </ListItemIcon>
                  <ListItemText>File</ListItemText>
                </ListItem>
              </List>
              <Typography id="upload-warning">
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Important:
                </Typography>{" "}
                Once you start uploading your log, leave this page open until
                the upload is complete
              </Typography>
              <LoadingButton
                aria-describedby="upload-warning"
                type="button"
                color="primary"
                variant="contained"
                startIcon={<CloudUpload />}
                loading={uploadLog.isLoading}
                {...beginUploadHandleProps}
              >
                Upload Log
              </LoadingButton>
              {uploadLog.isLoading && (
                <Stack sx={{ width: 1, pt: 2 }}>
                  <Typography id="progress-label" paragraph>
                    {uploadLog.uploadStatus.isComplete
                      ? "Finishing up..."
                      : uploadLog.uploadStatus.isUploading
                        ? `Upload in progress: ${formatPercent(uploadLog.uploadStatus.progress)}`
                        : "Preparing to upload..."}
                  </Typography>
                  <LinearProgress
                    aria-labelledby="progress-label"
                    variant="determinate"
                    value={uploadLog.uploadStatus.progress * 100}
                  />
                </Stack>
              )}
              {uploadLog.isSuccess && (
                <SuccessAlert logId={uploadLog.data.data.id} />
              )}
              {/* If `errors` is empty but the upload failed, it means
                some otherwise-unknown error occurred that isn't directly
                related to any of the form inputs. */}
              {uploadLog.isError && isEmpty(errors) && (
                <Alert severity="error" variant="filled" sx={{ width: 1 }}>
                  <AlertTitle>Error</AlertTitle>
                  An unknown error occurred trying to upload the log
                </Alert>
              )}
            </Stack>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}

type SectionCompletionStatus = "incomplete" | "complete" | "error";

function renderStatusIcon(status: SectionCompletionStatus): React.JSX.Element {
  switch (status) {
    case "incomplete": {
      return <RadioButtonUnchecked />;
    }
    case "complete": {
      return <CheckCircle color="success" />;
    }
    case "error": {
      return <Cancel color="error" />;
    }
    default: {
      assertNever(status);
    }
  }
}

function renderSectionHeader(
  status: SectionCompletionStatus,
  title: string,
): React.JSX.Element {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      {renderStatusIcon(status)}
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
    </Stack>
  );
}

function SuccessAlert({ logId }: { logId: Log["id"] }) {
  const lqsNavigator = useLqsNavigator({ toLogDetails: true });

  return (
    <Alert severity="success" variant="filled" sx={{ width: 1 }}>
      <AlertTitle>Log Uploaded</AlertTitle>
      Your log has been uploaded and is processing.{" "}
      <Link
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId })}
        color="inherit"
      >
        View it in the player
      </Link>
      {" or "}
      <Link
        component={RouterLink}
        to={lqsNavigator.toLogDetails({ logId })}
        color="inherit"
      >
        view its details page.
      </Link>
      .
    </Alert>
  );
}

function selectGroupOptions(
  response: GroupListResponse,
): ReadonlyArray<Option> {
  return response.data.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

function buildDocsEndpoint(): string {
  return buildLqsRestApiDocsEndpoint(getAppConfig().apiEndpoint);
}
