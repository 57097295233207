import type React from "react";
import type { WritableDraft } from "immer";
import type { ImmerReducer } from "use-immer";
import { millisecondsToNanoseconds, secondsToNanoseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import type { TimeRange } from "~/types";
import { assertNever, bigintClamp, bigintMax, bigintMin } from "~/utils";
import type { TimestepValue } from "../types";
import { Timestep } from "../types";

export type PlaybackMode = "single" | "range";

export type PlaybackSourceStatus = "paused" | "playing" | "range-mode";

export interface PlaybackSourceState {
  status: PlaybackSourceStatus;
  range: TimeRange | undefined;
  timestamp: bigint | undefined;
}

export interface BasePlaybackSource
  extends Pick<PlaybackSourceState, "range" | "timestamp"> {
  isLoading: boolean;
  bounds: TimeRange | undefined;
  mode: PlaybackMode;
  inRangeMode: boolean;
  isPlaying: boolean;
  dispatch: React.Dispatch<PlaybackAction>;
}

export interface LoadingPlaybackSource extends BasePlaybackSource {
  isLoading: true;
  bounds: undefined;
  mode: "single";
  inRangeMode: false;
  isPlaying: false;
  range: undefined;
  timestamp: undefined;
}

export interface LoadedPlaybackSource extends BasePlaybackSource {
  isLoading: false;
  bounds: TimeRange;
  range: TimeRange;
  timestamp: bigint;
}

export type PlaybackSource = LoadingPlaybackSource | LoadedPlaybackSource;

interface PlaybackActionRegistry {
  /**
   * Pause playback.
   *
   * Invariants:
   *  - Must be playing
   */
  pause: never;
  /**
   * Start playing from current timestamp.
   *
   * Invariants:
   *  - Must be paused
   */
  play: never;
  /**
   * Manually set the digestion time range. Allows the user to fine-tune the
   * range.
   *
   * Invariants:
   *  - Must be in range mode
   */
  // TODO: Should update playback timestamp as well
  "set-range": {
    range: LoadedPlaybackSource["range"];
  };
  /**
   * Enter into range-selection mode. Mutually exclusive state with normal
   * playback. Playback will be paused. Meant for manually adjusting digestion
   * time range.
   *
   * When entering range mode, the digestion range will be set to start 15
   * seconds prior to the current playback timestamp (clamped at the log's
   * start
   * if needed). However, if the current playback timestamp is already at the
   * log's start, the range will instead be set to end 15 seconds after the
   * log's start (clamped at the log's end if needed).
   *
   * Invariants:
   *  - Must be in playback mode
   */
  "enter-range-mode": never;
  /**
   * Exit range-select mode. Signals the user has finished manually adjusting
   * digestion time range. Returns to a paused playback state.
   *
   * Invariants:
   *  - Must be in range mode
   */
  "exit-range-mode": never;
  /**
   * Seek the playback timestamp directly to the provided timestamp (in
   * nanoseconds). If provided timestamp is at the end of the log playback
   * will be paused (if it wasn't already), otherwise playback will remain in
   * its current state.
   *
   * Invariants:
   *  - Must be in playback mode
   *  - Timestamp must be within log's bounds (can be equal to either bound)
   */
  seek: {
    to: bigint;
  };
  /**
   * Seeks to the previous frame from the current timestamp taking into
   * consideration the playback timestep setting. Always pauses playback.
   * Cannot be performed if already at log's start but will allow and clamp a
   * seek that would put the timestamp past the log's start.
   *
   * Invariants:
   *  - Must be in playback mode
   *  - Current timestamp must not be at start of log
   */
  "previous-frame": never;
  /**
   * Seeks to the next frame from the current timestamp taking into
   * consideration the playback timestep setting. Always pauses playback.
   * Cannot be performed if already at the log's end but will allow and clamp a
   * seek that would put the timestamp past the log's end.
   *
   * Invariants:
   *  - Must be in playback mode
   *  - Current timestamp must not be at end of log
   */
  "next-frame": never;
  /**
   * Perform a single tick of the simulated clock. Equivalent to performing the
   * "next-frame" action but continuing playback unless the next frame is at the
   * end of the log.
   *
   * Invariants*:
   *  - Must be in playback mode
   *  - Must be playing
   *  - Current timestamp must not be at end of log
   *
   * *If these conditions are not met the reducer will return the existing state
   *   unchanged rather than throwing an invariant violation. This is due to
   *   the asynchronous interplay between the browser executing the interval
   *   call, React updating the reducer's state, and React cleaning up the
   *   previous effect that will cancel the interval.
   */
  tick: never;
  /**
   * Restart playback from the start of the log and automatically play.
   *
   * Invariants:
   *  - Must be in playback mode
   */
  restart: never;
  /**
   * Skip to the first timestamp of the panel dispatching this action. If
   * playback is at or past the timestamp nothing will happen.
   *
   * Invariants:
   *  - Must be in playback mode
   */
  "auto-skip-to-first-timestamp": {
    firstTimestamp: bigint;
  };
}

export type PlaybackAction<
  TActionType extends
    keyof PlaybackActionRegistry = keyof PlaybackActionRegistry,
> = {
  [ActionType in TActionType]: PlaybackActionRegistry[ActionType] extends never
    ? {
        type: ActionType;
      }
    : {
        type: ActionType;
        payload: PlaybackActionRegistry[ActionType];
      };
}[TActionType];

type PlaybackActionCreator<TActionType extends keyof PlaybackActionRegistry> =
  PlaybackAction<TActionType> extends { payload: unknown }
    ? (
        payload: PlaybackAction<TActionType>["payload"],
      ) => PlaybackAction<TActionType>
    : () => PlaybackAction<TActionType>;

function createActionCreator<
  const TActionType extends keyof PlaybackActionRegistry,
>(type: TActionType): PlaybackActionCreator<TActionType> {
  return function createPlaybackAction(payload) {
    if (payload === undefined) {
      return {
        type,
      };
    } else {
      return {
        type,
        payload,
      };
    }
  } as PlaybackActionCreator<TActionType>;
}

export const pause = createActionCreator("pause");

export const play = createActionCreator("play");

export const setRange = createActionCreator("set-range");

export const enterRangeMode = createActionCreator("enter-range-mode");

export const exitRangeMode = createActionCreator("exit-range-mode");

export const seek = createActionCreator("seek");

export const previousFrame = createActionCreator("previous-frame");

export const nextFrame = createActionCreator("next-frame");

export const tick = createActionCreator("tick");

export const restart = createActionCreator("restart");

export const autoSkipToFirstTimestamp = createActionCreator(
  "auto-skip-to-first-timestamp",
);

export const initialState: PlaybackSourceState = {
  status: "paused",
  range: undefined,
  timestamp: undefined,
};

export function makeReducer(
  playerBounds: TimeRange | undefined,
  initialTime: bigint | undefined,
  timestep: TimestepValue,
): ImmerReducer<PlaybackSourceState, PlaybackAction> {
  return function reducer(state, action) {
    invariant(
      playerBounds !== undefined && initialTime !== undefined,
      "Bounds and initial time must be defined",
    );

    const { timestamp: currentTimestamp = initialTime } = state;

    switch (action.type) {
      case "pause": {
        invariant(state.status === "playing", "Can only pause when playing");

        state.status = "paused";

        return;
      }
      case "play": {
        invariant(state.status === "paused", "Can only play when paused");

        state.status = "playing";

        return;
      }
      case "set-range": {
        invariant(
          state.status === "range-mode",
          "Can only set range directly in range mode",
        );

        state.range = {
          startTime: bigintMax(
            playerBounds.startTime,
            action.payload.range.startTime,
          ),
          endTime: bigintMin(
            playerBounds.endTime,
            action.payload.range.endTime,
          ),
        };

        return;
      }
      case "enter-range-mode": {
        invariant(state.status !== "range-mode", "Already in range mode");

        // When entering range mode in typical cases the range should end
        // at the current playback timestamp and start 15 seconds prior (clamped
        // if the playback bounds aren't that long). If the current playback
        // timestamp is at the start of the bounds though, do the opposite: start
        // the range at the current timestamp and end 15 seconds later (with
        // clamping).
        //
        // The idea is that, in most cases, if someone wants to select manually
        // they're probably stopped at something interesting and want to select
        // records in the time leading up to it.
        if (currentTimestamp === playerBounds.startTime) {
          state.range = {
            startTime: playerBounds.startTime,
            endTime: bigintMin(
              playerBounds.startTime + secondsToNanoseconds(15),
              playerBounds.endTime,
            ),
          };
        } else {
          state.range = {
            startTime: bigintMax(
              currentTimestamp - secondsToNanoseconds(15),
              playerBounds.startTime,
            ),
            endTime: currentTimestamp,
          };
        }

        state.status = "range-mode";

        return;
      }
      case "exit-range-mode": {
        invariant(state.status === "range-mode", "Not in range mode");

        state.status = "paused";

        return;
      }
      case "seek": {
        const {
          payload: { to },
        } = action;

        performSeek({
          state,
          to,
          startTime: playerBounds.startTime,
          endTime: playerBounds.endTime,
        });

        return;
      }
      case "previous-frame": {
        if (currentTimestamp === playerBounds.startTime) {
          return;
        }

        state.timestamp = calculateFrameSeekTimestamp({
          currentTimestamp,
          direction: "previous",
          playerBounds,
          timestep,
        });
        state.status = "paused";

        return;
      }
      case "next-frame": {
        if (currentTimestamp === playerBounds.endTime) {
          return;
        }

        state.timestamp = calculateFrameSeekTimestamp({
          currentTimestamp,
          direction: "next",
          playerBounds,
          timestep,
        });
        state.status = "paused";

        return;
      }
      case "tick": {
        if (
          state.status !== "playing" ||
          currentTimestamp === playerBounds.endTime
        ) {
          return;
        }

        const nextTimestamp = calculateFrameSeekTimestamp({
          currentTimestamp,
          direction: "next",
          playerBounds,
          timestep,
        });

        state.timestamp = nextTimestamp;
        // Automatically pause at the end of the log
        state.status =
          nextTimestamp === playerBounds.endTime ? "paused" : "playing";

        return;
      }
      case "restart": {
        state.timestamp = playerBounds.startTime;
        state.status = "playing";

        return;
      }
      case "auto-skip-to-first-timestamp": {
        const {
          payload: { firstTimestamp },
        } = action;

        if (currentTimestamp >= firstTimestamp) {
          return;
        }

        performSeek({
          state,
          to: firstTimestamp,
          startTime: playerBounds.startTime,
          endTime: playerBounds.endTime,
        });

        return;
      }
      default: {
        assertNever(action);
      }
    }
  };
}

function performSeek({
  state,
  to,
  startTime,
  endTime,
}: {
  state: WritableDraft<PlaybackSourceState>;
  to: bigint;
  startTime: bigint;
  endTime: bigint;
}): void {
  const clampedTo = bigintClamp(to, startTime, endTime);

  state.timestamp = clampedTo;

  if (state.status === "playing" && clampedTo === endTime) {
    state.status = "paused";
  }
}

function calculateFrameSeekTimestamp({
  currentTimestamp,
  direction,
  playerBounds,
  timestep,
}: {
  currentTimestamp: bigint;
  direction: "previous" | "next";
  playerBounds: LoadedPlaybackSource["bounds"];
  timestep: TimestepValue;
}) {
  const absoluteOffset =
    timestep === Timestep.Second
      ? secondsToNanoseconds(1)
      : millisecondsToNanoseconds(100);
  const offset = direction === "next" ? absoluteOffset : -absoluteOffset;

  // It's valid for a frame seek to potentially put you past a bound. For
  // example, the user could seek to the second frame at decisecond frequency,
  // switch to second frequency, and then seek to the previous frame.
  return bigintClamp(
    currentTimestamp + offset,
    playerBounds.startTime,
    playerBounds.endTime,
  );
}
