import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Pause,
  PlayArrow,
  Replay,
  Share,
  SkipNext,
  SkipPrevious,
  ViewComfy,
} from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  skeletonClasses,
  Slider,
  sliderClasses,
  SpeedDial,
  SpeedDialAction,
  speedDialActionClasses,
  speedDialClasses,
  SpeedDialIcon,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { PlaySpeed, SquareWave } from "mdi-material-ui";
import { useSnackbar } from "notistack";
import { QueryRenderer } from "~/components/QueryRenderer";
import { LEFTWARDS_ARROW, RIGHTWARDS_ARROW } from "~/constants";
import { useCopyToClipboard } from "~/hooks/useCopyToClipboard";
import { useIsConstrainedHeight, useIsConstrainedWidth } from "~/layout";
import {
  millisecondsToNanoseconds,
  relativeToUtcNanoseconds,
  secondsToNanoseconds,
  utcToRelativeNanoseconds,
} from "~/lib/dates";
import { useHotkeys } from "~/lib/hotkeys";
import { invariant } from "~/lib/invariant";
import { pluralize } from "~/utils";
import { usePlayerActions } from "../actions";
import { useDataStoreLayoutProfiles } from "../hooks";
import type { LayoutProfile } from "../panels";
import { useLayoutProfiles } from "../panels";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSettings,
  usePlaybackSource,
  usePlaybackTimer,
  usePlaybackTimerPause,
} from "../playback";
import type { PlaybackTag } from "../tags";
import { PlaybackTags, usePlaybackTags, useShouldShowTags } from "../tags";
import type { TimestepValue } from "../types";
import { PlaybackSpeed, Timestep } from "../types";
import { ProfileDialog } from "./profile-dialog";

const classNames = {
  sliderContainer: "slider-container",
  tagsLoading: "tags-loading",
  playbackTimestamp: "playback-timestamp",
  controlsSection: "controls-section",
  leftControls: "left-controls",
  centerControls: "center-controls",
  rightControls: "right-controls",
} as const;

const Root = styled("div")(({ theme }) => ({
  flex: "none",
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  '&[data-constrained-size="true"]': {
    padding: theme.spacing(1),
    [`& .${sliderClasses.root}`]: {
      paddingBlock: theme.spacing(1.5),
    },
  },
  [`& .${skeletonClasses.root}`]: {
    display: "inline-block",
  },
  [`& .${classNames.sliderContainer}`]: {
    marginInline: theme.spacing(2),
    position: "relative",
  },
  [`& .${sliderClasses.root}`]: {
    display: "block",
    [`& :is(.${sliderClasses.track}, .${sliderClasses.thumb})`]: {
      transition: "none",
    },
    [`& .${sliderClasses.thumb}`]: {
      // Custom marks are positioned over the rest of the slider,
      // specifically the padding area so it doesn't steal click events
      // meant for the mark. However, the thumb still needs to appear
      // over the custom marks.
      // Since custom marks also use a z-index on hover to appear over
      // neighboring marks, the thumb's z-index must ensure it still
      // appears over hovered custom markers.
      zIndex: 2,
    },
  },
  [`& .${classNames.tagsLoading}`]: {
    position: "absolute",
    top: 0,
    translate: "0 -75%",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  [`& .${classNames.playbackTimestamp}`]: {
    ...theme.typography.body2,
    margin: 0,
    display: "inline",
    fontFamily: "monospace",
  },
  [`& .${classNames.controlsSection}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridTemplateAreas: '"left center right"',
  },
  [`& .${classNames.leftControls}`]: {
    gridArea: "left",
    display: "flex",
    alignItems: "center",
  },
  [`& .${classNames.centerControls}`]: {
    gridArea: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classNames.rightControls}`]: {
    gridArea: "right",
    position: "relative",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
}));

function offsetNsForTimestep(timestep: TimestepValue): number {
  return Number(
    timestep === Timestep.Second
      ? secondsToNanoseconds(1)
      : millisecondsToNanoseconds(100),
  );
}

export function PlaybackController({
  disableSettingDefaultLayout,
}: {
  disableSettingDefaultLayout?: boolean;
}) {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const isConstrainedWidth = useIsConstrainedWidth();
  const isConstrainedHeight = useIsConstrainedHeight();
  const controlsSize = isConstrainedHeight ? "small" : "medium";

  const offsetNs = offsetNsForTimestep(playbackSettings.timestep);

  const playbackTimer = usePlaybackTimer();

  const [isSeeking, setIsSeeking] = useState(false);
  usePlaybackTimerPause(isSeeking);

  const playerActions = usePlayerActions();
  const { tick: dispatchTick } = playerActions;

  useEffect(
    function managePlaybackTimer() {
      if (!playbackSource.isPlaying) {
        return;
      }

      playbackTimer.set({
        onTick: dispatchTick,
        speed: playbackSettings.speed,
        timestep: playbackSettings.timestep,
      });

      return () => {
        playbackTimer.clear();
      };
    },
    [
      playbackSource.isPlaying,
      playbackTimer,
      dispatchTick,
      playbackSettings.speed,
      playbackSettings.timestep,
    ],
  );

  const { spaceRef, leftArrowRef, rightArrowRef } = usePlaybackHotkeys();

  const shouldShowTags = useShouldShowTags();
  const playbackTagsQuery = usePlaybackTags();

  function handleSliderChange(
    _: unknown,
    value: number | Array<number>,
    thumbIndex: number,
  ): void {
    invariant(!playbackSource.isLoading, "Slider value shouldn't be changing");

    setIsSeeking(true);

    if (playbackSource.inRangeMode) {
      invariant(
        Array.isArray(value) && value.length === 2,
        "Expected a 2-tuple in range-select mode",
      );

      playerActions.setRange({
        startTime: relativeToUtcNanoseconds(
          value[0],
          playbackSource.bounds.startTime,
        ),
        endTime: relativeToUtcNanoseconds(
          value[1],
          playbackSource.bounds.startTime,
        ),
      });
      playerActions.seek(
        relativeToUtcNanoseconds(
          value[thumbIndex],
          playbackSource.bounds.startTime,
        ),
      );
    } else {
      invariant(
        typeof value === "number",
        "Expected a number in playback mode",
      );

      playerActions.seek(
        relativeToUtcNanoseconds(value, playbackSource.bounds.startTime),
      );
    }
  }

  const isAtStart =
    playbackSource.timestamp === playbackSource.bounds?.startTime;
  const isAtEnd = playbackSource.timestamp === playbackSource.bounds?.endTime;

  let playbackControl;
  if (playbackSource.isPlaying) {
    playbackControl = (
      <Tooltip title="Pause (space)">
        <span>
          <IconButton
            ref={spaceRef}
            disabled={playbackSource.inRangeMode}
            aria-label="Pause log playback"
            onClick={playerActions.pause}
            size={controlsSize}
          >
            <Pause fontSize={controlsSize} />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else if (!playbackSource.isLoading && isAtEnd) {
    playbackControl = (
      <Tooltip title="Replay (space)">
        <span>
          <IconButton
            ref={spaceRef}
            disabled={playbackSource.inRangeMode}
            aria-label="Replay log from beginning"
            onClick={playerActions.restart}
            size={controlsSize}
          >
            <Replay fontSize={controlsSize} />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else {
    playbackControl = (
      <Tooltip title="Play (space)">
        <span>
          <IconButton
            ref={spaceRef}
            disabled={playbackSource.isLoading || playbackSource.inRangeMode}
            aria-label="Start or resume log playback"
            onClick={playerActions.play}
            size={controlsSize}
          >
            <PlayArrow fontSize={controlsSize} />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Root data-constrained-size={isConstrainedWidth || isConstrainedHeight}>
      <div className={classNames.sliderContainer}>
        <Slider
          size={controlsSize}
          disabled={playbackSource.isLoading}
          valueLabelDisplay={playbackSource.inRangeMode ? "on" : "auto"}
          valueLabelFormat={formatPlaybackTimestamp}
          min={0}
          max={
            playbackSource.isLoading
              ? 0
              : utcToRelativeNanoseconds(
                  playbackSource.bounds.endTime,
                  playbackSource.bounds.startTime,
                )
          }
          step={offsetNs}
          onChange={handleSliderChange}
          onChangeCommitted={() => {
            setIsSeeking(false);
          }}
          value={
            playbackSource.inRangeMode
              ? [
                  utcToRelativeNanoseconds(
                    playbackSource.range.startTime,
                    playbackSource.bounds.startTime,
                  ),
                  utcToRelativeNanoseconds(
                    playbackSource.range.endTime,
                    playbackSource.bounds.startTime,
                  ),
                ]
              : utcToRelativeNanoseconds(
                  playbackSource.timestamp ?? 0n,
                  playbackSource.bounds?.startTime ?? 0n,
                )
          }
        />
        {playbackTagsQuery.isFetching && (
          <div className={classNames.tagsLoading}>
            <CircularProgress size="1rem" />
            <Typography>Loading tags...</Typography>
          </div>
        )}
        {shouldShowTags &&
          !playbackSource.isLoading &&
          playbackTagsQuery.isSuccess && (
            <PlaybackTags tags={playbackTagsQuery.data} />
          )}
      </div>
      <div className={classNames.controlsSection}>
        <div className={classNames.leftControls}>
          {!isConstrainedWidth && (
            <Tooltip title={`Previous (${LEFTWARDS_ARROW})`}>
              <span>
                <IconButton
                  ref={leftArrowRef}
                  disabled={
                    isAtStart ||
                    playbackSource.isLoading ||
                    playbackSource.inRangeMode
                  }
                  aria-label="Move backward to previous timestamp"
                  onClick={playerActions.previousFrame}
                  size={controlsSize}
                >
                  <SkipPrevious fontSize={controlsSize} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {playbackControl}
          <Tooltip title={`Next (${RIGHTWARDS_ARROW})`}>
            <span>
              <IconButton
                ref={rightArrowRef}
                disabled={
                  isAtEnd ||
                  playbackSource.isLoading ||
                  playbackSource.inRangeMode
                }
                aria-label="Move forward to next timestamp"
                onClick={playerActions.nextFrame}
                size={controlsSize}
              >
                <SkipNext fontSize={controlsSize} />
              </IconButton>
            </span>
          </Tooltip>
          <div>
            <pre className={classNames.playbackTimestamp}>
              {playbackSource.isLoading
                ? "--"
                : formatPlaybackTimestamp(playbackSource.timestamp)}
            </pre>
            {" / "}
            <pre className={classNames.playbackTimestamp}>
              {playbackSource.isLoading
                ? "--"
                : formatPlaybackTimestamp(playbackSource.bounds.endTime)}
            </pre>
          </div>
        </div>
        {!playbackSource.isLoading && shouldShowTags && (
          <QueryRenderer
            query={playbackTagsQuery}
            success={(tags) => {
              // Timestamps are assumed to be sorted in ascending order
              const previousTag = tags.findLast(
                (tag) => tag.startTime < playbackSource.timestamp,
              );
              const nextTag = tags.find(
                (tag) => tag.startTime > playbackSource.timestamp,
              );

              function createTagSeekHandler(tag: PlaybackTag | undefined) {
                return function handleTagSeek() {
                  if (tag === undefined) {
                    return;
                  }

                  playerActions.seek(tag.startTime);
                };
              }

              return (
                <div className={classNames.centerControls}>
                  <Tooltip title="Previous tag">
                    <span>
                      <IconButton
                        disabled={previousTag === undefined}
                        onClick={createTagSeekHandler(previousTag)}
                        size={controlsSize}
                      >
                        <KeyboardDoubleArrowLeft fontSize={controlsSize} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Typography color="text.secondary">
                    {pluralize(tags.length, "tag")}
                  </Typography>
                  <Tooltip title="Next tag">
                    <span>
                      <IconButton
                        disabled={nextTag === undefined}
                        onClick={createTagSeekHandler(nextTag)}
                        size={controlsSize}
                      >
                        <KeyboardDoubleArrowRight fontSize={controlsSize} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              );
            }}
          />
        )}
        <SupplementaryControls
          disableSettingDefaultLayout={disableSettingDefaultLayout}
        />
      </div>
    </Root>
  );
}

function SupplementaryControls({
  disableSettingDefaultLayout = false,
}: {
  disableSettingDefaultLayout?: boolean;
}) {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const playerActions = usePlayerActions();

  const dataStoreLayoutProfiles = useDataStoreLayoutProfiles();
  const layoutProfilesQuery = useLayoutProfiles();

  const speedMenuState = usePopupState({
    variant: "popover",
    popupId: "speed-menu",
  });
  const stepMenuStep = usePopupState({
    variant: "popover",
    popupId: "step-menu",
  });
  const profilesMenuState = usePopupState({
    variant: "popover",
    popupId: "profiles-menu",
  });

  function handleOpenProfileDialog() {
    setProfileDialogOpen(true);

    profilesMenuState.close();
  }

  const copyToClipboard = useCopyToClipboard();

  const { enqueueSnackbar } = useSnackbar();

  function handleCopyShareableLink() {
    invariant(!playbackSource.isLoading, "Not loaded");

    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.set("t", String(playbackSource.timestamp));

    const shareableLink = currentUrl.toString();

    copyToClipboard.mutate(shareableLink, {
      onSuccess() {
        enqueueSnackbar("Link copied", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Unable to copy link", {
          variant: "error",
        });
      },
    });
  }

  function renderProfileMenuItem(profile: LayoutProfile) {
    function handleSelectLayout() {
      playerActions.loadLayout(profile.layout);
      profilesMenuState.close();
    }

    return (
      <MenuItem key={profile.name} onClick={handleSelectLayout}>
        {profile.name}
      </MenuItem>
    );
  }

  const isConstrainedWidth = useIsConstrainedWidth();
  const isConstrainedHeight = useIsConstrainedHeight();
  const controlsSize = isConstrainedHeight ? "small" : "medium";

  return (
    <div className={classNames.rightControls}>
      {isConstrainedWidth ? (
        <SpeedDial
          ariaLabel="Supplementary controls"
          icon={<SpeedDialIcon />}
          FabProps={{
            size: "small",
            disabled: playbackSource.isLoading,
          }}
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            [`& .${speedDialClasses.fab}`]: {
              minHeight: 0,
              width: 30,
              height: 30,
            },
            [`& .${speedDialActionClasses.staticTooltipLabel}`]: {
              width: "max-content",
            },
          }}
        >
          <SpeedDialAction
            tooltipTitle="Layout profiles"
            tooltipOpen
            icon={<ViewComfy />}
            FabProps={{
              disabled: !layoutProfilesQuery.isSuccess,
            }}
            {...bindTrigger(profilesMenuState)}
          />
          <SpeedDialAction
            tooltipTitle="Copy shareable link"
            tooltipOpen
            icon={<Share />}
            onClick={handleCopyShareableLink}
          />
          <SpeedDialAction
            tooltipTitle="Playback speed"
            tooltipOpen
            icon={<PlaySpeed />}
            {...bindTrigger(speedMenuState)}
          />
          <SpeedDialAction
            tooltipTitle="Playback timestep"
            tooltipOpen
            icon={<SquareWave />}
            {...bindTrigger(stepMenuStep)}
          />
        </SpeedDial>
      ) : (
        <>
          <Tooltip title="Layout profiles">
            <span>
              <IconButton
                disabled={
                  playbackSource.isLoading || !layoutProfilesQuery.isSuccess
                }
                size={controlsSize}
                {...bindTrigger(profilesMenuState)}
              >
                <ViewComfy fontSize={controlsSize} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Copy shareable link">
            <span>
              <IconButton
                onClick={handleCopyShareableLink}
                disabled={playbackSource.isLoading}
                size={controlsSize}
              >
                <Share fontSize={controlsSize} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Playback speed">
            <span>
              <IconButton
                disabled={playbackSource.isLoading}
                aria-label="Open playback speed menu"
                size={controlsSize}
                {...bindTrigger(speedMenuState)}
              >
                <PlaySpeed fontSize={controlsSize} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Playback timestep">
            <span>
              <IconButton
                disabled={playbackSource.isLoading}
                aria-label="Open timestep control menu"
                size={controlsSize}
                {...bindTrigger(stepMenuStep)}
              >
                <SquareWave fontSize={controlsSize} />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
      {layoutProfilesQuery.isSuccess && (
        <Menu {...bindMenu(profilesMenuState)}>
          {dataStoreLayoutProfiles.length > 0 && (
            <ListSubheader sx={{ lineHeight: 2 }} disableSticky>
              DataStore Layouts
            </ListSubheader>
          )}
          {dataStoreLayoutProfiles.map(renderProfileMenuItem)}
          {dataStoreLayoutProfiles.length > 0 && (
            <Divider component="li" sx={{ my: 1 }} />
          )}
          {layoutProfilesQuery.data.length > 0 && (
            <ListSubheader sx={{ lineHeight: 2 }} disableSticky>
              Your Layouts
            </ListSubheader>
          )}
          {layoutProfilesQuery.data.map(renderProfileMenuItem)}
          {layoutProfilesQuery.data.length > 0 && (
            <Divider component="li" sx={{ my: 1 }} />
          )}
          <MenuItem onClick={handleOpenProfileDialog}>
            Manage Profiles...
          </MenuItem>
        </Menu>
      )}
      <ProfileDialog
        open={profileDialogOpen}
        setOpen={setProfileDialogOpen}
        disableSettingDefaultLayout={disableSettingDefaultLayout}
      />
      <Menu {...bindMenu(speedMenuState)}>
        <MenuItem
          selected={playbackSettings.speed === PlaybackSpeed.TimesOne}
          onClick={() => playerActions.setPlaybackSpeed(PlaybackSpeed.TimesOne)}
        >
          1x
        </MenuItem>
        <MenuItem
          selected={playbackSettings.speed === PlaybackSpeed.TimesTwo}
          onClick={() => playerActions.setPlaybackSpeed(PlaybackSpeed.TimesTwo)}
        >
          2x
        </MenuItem>
        <MenuItem
          selected={playbackSettings.speed === PlaybackSpeed.TimesFive}
          onClick={() =>
            playerActions.setPlaybackSpeed(PlaybackSpeed.TimesFive)
          }
        >
          5x
        </MenuItem>
        <MenuItem
          selected={playbackSettings.speed === PlaybackSpeed.TimesTen}
          onClick={() => playerActions.setPlaybackSpeed(PlaybackSpeed.TimesTen)}
        >
          10x
        </MenuItem>
      </Menu>
      <Menu {...bindMenu(stepMenuStep)}>
        <MenuItem
          selected={playbackSettings.timestep === Timestep.Second}
          onClick={() => playerActions.setPlaybackTimestep(Timestep.Second)}
        >
          1 second
        </MenuItem>
        <MenuItem
          selected={playbackSettings.timestep === Timestep.Decisecond}
          onClick={() => playerActions.setPlaybackTimestep(Timestep.Decisecond)}
        >
          0.1 seconds
        </MenuItem>
      </Menu>
    </div>
  );
}

function usePlaybackHotkeys() {
  const spaceRef = useRef<HTMLButtonElement | null>(null);
  useHotkeys(
    "Space",
    useCallback((e) => {
      if (e.target !== document.body) {
        return;
      }

      spaceRef.current?.click();
    }, []),
  );

  const leftArrowRef = useRef<HTMLButtonElement | null>(null);
  useHotkeys(
    "ArrowLeft",
    useCallback((e) => {
      if (e.target !== document.body) {
        return;
      }

      leftArrowRef.current?.click();
    }, []),
  );

  const rightArrowRef = useRef<HTMLButtonElement | null>(null);
  useHotkeys(
    "ArrowRight",
    useCallback((e) => {
      if (e.target !== document.body) {
        return;
      }

      rightArrowRef.current?.click();
    }, []),
  );

  // Rather than directly dispatching actions which would require knowing the
  // current player state and whether those actions should be disabled, a ref
  // can be attached to the button whose action should be triggered when the
  // hotkey is pressed. The hotkey handler just needs to programmatically click
  // the button which has the added benefit of doing nothing if the button
  // is disabled.
  return { spaceRef, leftArrowRef, rightArrowRef };
}
