import { nanosecondsToMilliseconds } from "~/lib/dates";
import type { TimeRange } from "~/types";

export function calculateWindowTicks(
  recordWindow: TimeRange,
  requestedIntervalLength: bigint,
  requestedWindowSize: bigint,
): Array<number> {
  const ticks = new Array<number>();
  if (recordWindow.endTime - recordWindow.startTime < requestedWindowSize) {
    // The record window's size is less than the requested window size,
    // indicating the playback bounds are less than the requested size. In this
    // case there won't be a sliding window effect since the window will always
    // cover the same data, so just calculate some static ticks.

    const tickCount = requestedWindowSize / requestedIntervalLength;
    const intervalLength =
      (recordWindow.endTime - recordWindow.startTime) / tickCount;

    for (
      let tick = recordWindow.startTime + intervalLength;
      tick < recordWindow.endTime;
      tick += intervalLength
    ) {
      // Discard any fractional part of the milliseconds since these ticks
      // will be converted back to nanoseconds when they're formatted. These
      // ticks could come out as fractions since they're relative to the
      // window's start time which isn't guaranteed to be a whole millisecond.
      ticks.push(nanosecondsToMilliseconds(tick, { preserveFraction: false }));
    }
  } else {
    // The record window's size equals the request window size, so the playback
    // bounds are at least as long as the requested window size. In this case
    // the record window will slide along as playback progresses, so the ticks
    // should be fixed to points in time, not positions within the window.

    // Floor to a multiple of the interval size
    let firstTick =
      (recordWindow.startTime / requestedIntervalLength) *
      requestedIntervalLength;
    if (firstTick < recordWindow.startTime) {
      firstTick += requestedIntervalLength;
    }

    for (
      let tick = firstTick;
      tick < recordWindow.endTime;
      tick += requestedIntervalLength
    ) {
      ticks.push(nanosecondsToMilliseconds(tick));
    }
  }

  return ticks;
}
