import type { ValueOf } from "ts-essentials";
import type { OverrideProperties } from "type-fest";
import type { Log } from "~/lqs";
import type { TimeRange } from "~/types";

// Record down-sampling frequency. Units are records per second
export const SampleFrequency = {
  Second: 1,
  Decisecond: 10,
} as const;

export type SampleFrequencyValue = ValueOf<typeof SampleFrequency>;

export const PlaybackSpeed = {
  TimesOne: 1,
  TimesTwo: 2,
  TimesFive: 5,
  TimesTen: 10,
} as const;

export type PlaybackSpeedValue = ValueOf<typeof PlaybackSpeed>;

export const Timestep = {
  Second: "second",
  Decisecond: "decisecond",
} as const;

export type TimestepValue = ValueOf<typeof Timestep>;

export type BoundedLog = OverrideProperties<Log, TimeRange>;
