import React from "react";
import { Close } from "@mui/icons-material";
import {
  alpha,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FolderOpen } from "mdi-material-ui";
import prettyBytes from "pretty-bytes";
import type { Accept, FileError } from "react-dropzone";
import { ErrorCode, useDropzone } from "react-dropzone";
import type {
  FieldPathByValue,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";
import { useController } from "react-hook-form";
import * as z from "zod";
import { MultipartUploader } from "~/domain/network";
import type { BaseFieldProps } from "./types";

const customProperties = {
  dashColor: "--dash-color",
  bgColor: "--bg-color",
} as const;

const classNames = {
  selectionArea: "selection-area",
} as const;

// 8px-long colored dash followed by 8px-long transparent dash
const gradientStops = `var(${customProperties.dashColor}) 0px 8px, transparent 8px 16px`;

const Root = styled(Stack)(({ theme }) => ({
  width: "100%",
  paddingTop: theme.spacing(4),
  justifyContent: "center",
  alignItems: "center",
  // Make space for the background image
  border: "1px transparent",
  background: [
    `repeating-linear-gradient(to top, ${gradientStops}) bottom left / 1px 100%`,
    `repeating-linear-gradient(to right, ${gradientStops}) top left / 100% 1px`,
    `repeating-linear-gradient(to bottom, ${gradientStops}) top right / 1px 100%`,
    `repeating-linear-gradient(to left, ${gradientStops}) bottom right / 100% 1px`,
  ].join(", "),
  backgroundRepeat: "no-repeat",
  // Trailing comma is intentional
  backgroundColor: `var(${customProperties.bgColor},)`,
  [`& .${classNames.selectionArea}`]: {
    width: "100%",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    borderTop: "1px transparent",
    background: `repeating-linear-gradient(to right, ${gradientStops}) top left / 100% 1px no-repeat`,
  },
}));

export const requiredFile = z
  .unknown()
  .superRefine((value, ctx): value is File => {
    const isFile = value instanceof File;
    if (isFile) {
      return true;
    } else if (value == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "File is required",
        fatal: true,
      });

      return z.NEVER;
    } else {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Expected a file",
        fatal: true,
      });

      return z.NEVER;
    }
  });

interface FileFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, File | null>,
> extends Pick<BaseFieldProps<TFieldValues, TName>, "control" | "name"> {
  accept?: Accept;
  acceptedFormats?: ReadonlyArray<string>;
  setError: UseFormSetError<TFieldValues>;
  clearErrors: UseFormClearErrors<TFieldValues>;
}

export function FileField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, File | null>,
>({
  control,
  name,
  accept,
  acceptedFormats,
  setError,
  clearErrors,
}: FileFieldProps<TFieldValues, TName>) {
  const theme = useTheme();

  const { field, fieldState } = useController({ control, name });

  const fieldValue = field.value as File | null;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: MultipartUploader.MAX_FILE_SIZE_BYTES,
    accept,
    onDrop(acceptedFiles, rejectedFiles) {
      clearErrors(name);

      const errors = new Set<FileError["code"]>();
      rejectedFiles.forEach((file) =>
        file.errors.forEach((error) => errors.add(error.code)),
      );

      if (errors.size > 0) {
        field.onChange(null);
      }

      if (errors.has(ErrorCode.TooManyFiles)) {
        setError(name, { message: "Only one file can be uploaded" });
        return;
      }

      if (errors.has(ErrorCode.FileInvalidType)) {
        setError(name, { message: "File type is not supported" });
        return;
      }

      if (errors.has(ErrorCode.FileTooLarge)) {
        setError(name, {
          message: `Files must be less than ${prettyBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)}`,
        });
        return;
      }

      field.onChange(acceptedFiles[0]);
    },
  });

  function handleClearSelectedFile() {
    field.onChange(null);
    clearErrors(name);
  }

  return (
    <div>
      <Root
        {...getRootProps({
          style: {
            [customProperties.dashColor]:
              fieldState.error !== undefined
                ? theme.palette.error.main
                : theme.palette.text.primary,
            ...(isDragActive && {
              [customProperties.bgColor]: alpha(
                theme.palette.text.primary,
                0.05,
              ),
            }),
          },
        })}
      >
        <FolderOpen sx={{ fontSize: "5rem" }} />
        <span>Drag and drop a file</span>
        <Divider role="presentation" sx={{ width: 100, my: 1 }}>
          or
        </Divider>
        <Button
          type="button"
          aria-describedby="file-requirements"
          color="primary"
          variant="outlined"
          onClick={open}
        >
          Browse files
        </Button>
        <Typography id="file-requirements" sx={{ mt: 2 }}>
          Maximum file size:{" "}
          {prettyBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)}
          {acceptedFormats !== undefined && (
            <>, Accepted files: {acceptedFormats.join(", ")}</>
          )}
        </Typography>
        <input {...getInputProps()} />
        <div className={classNames.selectionArea}>
          {fieldValue === null ? (
            <Typography sx={{ fontStyle: "italic" }}>
              No file selected
            </Typography>
          ) : (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>
                {fieldValue.name} ({prettyBytes(fieldValue.size)})
              </Typography>
              <Tooltip title="Clear selected file">
                <IconButton
                  size="small"
                  color="error"
                  onClick={handleClearSelectedFile}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </div>
      </Root>
      <FormHelperText
        error={fieldState.error !== undefined}
        variant="outlined"
        sx={{ ml: 2 }}
      >
        {fieldState.error?.message ?? " "}
      </FormHelperText>
    </div>
  );
}
