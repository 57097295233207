import type { StrictExtract } from "ts-essentials";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import type { PanelNode } from "./api";
import { VisualizationType } from "./constants";

export type VisualizationFilter = StrictExtract<
  VisualizationType,
  "image" | "three-d" | "map"
>;

const IMAGE_TOPICS = [
  "sensor_msgs/Image",
  "sensor_msgs/CompressedImage",
  "stereo_msgs/DisparityImage",
  "sensor_msgs/msg/Image",
  "sensor_msgs/msg/CompressedImage",
  "stereo_msgs/msg/DisparityImage",
  "logqs.inference.depth-estimation",
  "logqs.inference.image-segmentation",
  "logqs.inference.image-to-image",
  "ark::image::Image",
  "logqs.image",
  "lqs_cutter/navcon_image",
];

const THREE_D_TOPICS = [
  "sensor_msgs/PointCloud2",
  "sensor_msgs/msg/PointCloud2",
];

const GPS_POSITION_TOPICS = [
  "sensor_msgs/NavSatFix",
  "sensor_msgs/msg/NavSatFix",
  "ark::gps::GpsFix",
  "lqs_cutter/navcon_gps",
];

const CARTESIAN_POSITION_TOPICS = ["lqs_cutter/navcon_pose_filter"];

export function getSupportedVisualizations(
  panel: PanelNode,
): Record<VisualizationType, boolean> {
  const defaultVisualization =
    panel.isInitialized && getDefaultVisualization(panel.topicTypeName);

  return {
    [VisualizationType.Image]: defaultVisualization === VisualizationType.Image,
    [VisualizationType.ThreeD]:
      defaultVisualization === VisualizationType.ThreeD,
    [VisualizationType.Map]: defaultVisualization === VisualizationType.Map,
    [VisualizationType.Chart]: panel.isInitialized && panel.fields.length > 0,
    [VisualizationType.Timeline]: true,
  };
}

export function getDefaultVisualization(
  typeName: Topic["typeName"],
): VisualizationType {
  switch (true) {
    case supportsVisualization(typeName, VisualizationType.Image): {
      return VisualizationType.Image;
    }
    case supportsVisualization(typeName, VisualizationType.ThreeD): {
      return VisualizationType.ThreeD;
    }
    case supportsVisualization(typeName, VisualizationType.Map): {
      return VisualizationType.Map;
    }
    default: {
      return VisualizationType.Timeline;
    }
  }
}

export function supportsVisualization(
  typeName: Topic["typeName"],
  visualization: VisualizationFilter,
): boolean {
  switch (visualization) {
    case VisualizationType.Image: {
      return IMAGE_TOPICS.includes(typeName!);
    }
    case VisualizationType.ThreeD: {
      return THREE_D_TOPICS.includes(typeName!);
    }
    case VisualizationType.Map: {
      return (
        GPS_POSITION_TOPICS.includes(typeName!) ||
        CARTESIAN_POSITION_TOPICS.includes(typeName!)
      );
    }
    default: {
      assertNever(visualization);
    }
  }
}

export function getMapSubVisualization(
  typeName: Topic["typeName"],
): "gps" | "cartesian" {
  switch (true) {
    case GPS_POSITION_TOPICS.includes(typeName!): {
      return "gps";
    }
    case CARTESIAN_POSITION_TOPICS.includes(typeName!): {
      return "cartesian";
    }
    default: {
      throw new Error(
        `Topic with type "${typeName}" does not support map visualization`,
      );
    }
  }
}
